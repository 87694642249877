import {Cart} from '@/components/types/cart'
import {i18n} from 'next-i18next'
import {v4} from 'uuid'

export const foundCountry = (searchValue?: string) => {
  const countriesArray = i18n?.t('countriesArray:countriesArray', {
    returnObjects: true
  })
  /* @ts-ignore */
  return countriesArray?.find(function (country: {
    text: string
    value: string
  }) {
    return country.value === searchValue
  })?.text
}

export const getFormattedCart = (data: {cart: Cart}) => {
  let formattedCart: any = {}

  if (undefined === data || !data.cart.contents.nodes.length) {
    return formattedCart
  }

  const givenProducts = data.cart.contents.nodes

  // Create an empty object.
  formattedCart.product = {}
  formattedCart.appliedCoupons = {}

  const givenProduct = givenProducts?.[0]?.product?.node
  const givenVariation = givenProducts?.[0]?.variation?.node
  const product: any = {}

  product.productId = givenProduct?.productId ?? ''
  product.cartKey = givenProducts?.[0]?.key ?? ''
  product.name = givenProduct?.name ?? ''
  product.totalPrice = givenProducts?.[0]?.total.replace('&nbsp;', ' ') ?? ''
  product.variation = givenVariation?.attributes?.nodes[0]?.value
  product.image = givenProduct?.image
  product.ProgramFields = givenProduct?.ProgramFields
  product.productCategories = givenProduct?.productCategories
  product.translations = givenProduct?.translations
  product.language = givenProduct?.language

  product.variationPrice = givenVariation?.price
  for (const attribute of givenVariation.attributes.nodes) {
    product[attribute.name] = attribute.valueName
      ? attribute.valueName
      : attribute.value
  }
  formattedCart.product = product
  formattedCart.total = data?.cart?.total.replace('&nbsp;', ' ') ?? ''
  formattedCart.subtotal = data?.cart?.subtotal.replace('&nbsp;', ' ') ?? ''
  formattedCart.subtotalTax =
    data?.cart?.subtotalTax.replace('&nbsp;', ' ') ?? ''
  formattedCart.taxPercentage = Math.round(
    100 *
      ((removeNonDigits(data?.cart?.total) +
        removeNonDigits(data?.cart?.discountTax) +
        removeNonDigits(data?.cart?.discountTotal)) /
        removeNonDigits(data?.cart?.subtotal) -
        1)
  )

  formattedCart.appliedCoupons = data?.cart?.appliedCoupons ?? ''

  return formattedCart
}

export const removeNonDigits = (string: string) => {
  const digits = string.replace(/\D/g, '')
  return parseInt(digits)
}

export const createCheckoutData = (order: any) => {
  const checkoutData = {
    clientMutationId: v4(),
    billing: {
      company: order?.customerACF?.billingSameAsContact
        ? order?.customerACF?.employer
        : order?.billing?.company,
      address2: order?.customerACF?.billingSameAsContact
        ? ''
        : order?.billing?.address2,
      firstName: order?.customerACF?.billingSameAsContact
        ? order.firstName
        : order?.billing?.firstName,
      lastName: order?.customerACF?.billingSameAsContact
        ? order.lastName
        : order?.billing?.lastName,
      address1: order?.customerACF?.billingSameAsContact
        ? order.customerACF.address
        : order?.billing?.address1,
      city: order?.customerACF?.billingSameAsContact
        ? order.customerACF.city
        : order?.billing?.city,
      country: order?.customerACF?.billingSameAsContact
        ? order.customerACF.country
        : order?.billing?.country,
      postcode: order?.customerACF?.billingSameAsContact
        ? order.customerACF.postcode
        : order?.billing?.postcode
    },
    paymentMethod: order?.paymentMethod,
    isPaid: false
  }
  return {checkoutData}
}
